import { styled, keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
    height: auto;
    padding: 10px 20px;
  }

  60% {
    opacity: 1;
    height: auto;
    padding: 10px 20px;
  }

  80% {
    opacity: 0.5;
    height: auto;
  }

  100% {
    opacity: 0;
    height: 0;
    padding: 0 20px;
  }
`;

export const BasketPanel = styled.div<{ open:boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100vw;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  border-left: 1px solid ${props => props.theme.colors.grey};
  width: 100%;
  transition: all 0.5s ease-in-out;
  ${props => props.open && 'right: 0;'}

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    max-width: 380px;
    ${props => props.open ? 'right: 0;' : 'right: -380px;'}
  }
`;

export const BasketTitle = styled.div`
  padding: 15px 20px;
  position: relative;
`;

export const BasketClose = styled.a`
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 15px;

  &::before,
  &::after {
    position: absolute;
    left: 10.5px;
    top: 3px;
    content: "";
    height: 18px;
    width: 3px;
    background-color: ${props => props.theme.colors.black};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export const ScrollableBasket = styled.div`
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

export const BasketNotification = styled('div').withConfig({
  shouldForwardProp: props =>
    !['error'].includes(props)
})<{error: boolean}>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes.zi2};
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: ${props => props.theme.lineHeights.lh1};
  background-color: ${props => props.theme.colors.successLight};
  opacity: 0;
  height: 0;

  &.fade-in {
    animation: ${fadeIn} ease-in-out 3s;
  }

  svg {
    color: ${props => props.theme.colors.black};
    margin-right: 10px;
    height: 24px;
  }

  ${props => props.error && css`
    background-color: ${props.theme.colors.errorLight};

    svg {
      color: ${props.theme.colors.black};
      height: 24px;
    }
  `}
`;

export const BasketItems = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  min-height: 130px;
  position: relative;
  overflow-y: auto;
  max-height: calc(100vh - 188px);
`;

export const BasketItemsOverlay = styled('div').withConfig({
  shouldForwardProp: props =>
    !['show'].includes(props)
})<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: ${props => props.theme.zIndexes.zi4};
  ${props => props.show && css`
    display: block;
  `}
`;

export const NoItems = styled.p`
  padding: 15px 20px;
`;

export const BasketTotal = styled.div`
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
`;

export const BasketCta = styled.div`
  padding: 15px 20px;
  background-color: ${props => props.theme.colors.lightGrey};
`;

export const BasketMore = styled.div`
  padding: 20px;

  ul {
    display: flex;
    width: 100%;
    padding: 15px 0;
    justify-content: space-between;

    li {
      display: block;
      width: 40%;
    }
  }
`;
