import React from 'react';
import { ProductImage, FreeGift } from '../../atoms';
import { ProductTile as ProductTileProps } from './productTile.types';
import ProductTileTitlePlaceholder from './placeholders/productTileTitlePlaceholder';
import ProductTileSubtitlePlaceholder from './placeholders/productTileSubtitlePlaceholder';
import ProductTilePricePlaceholder from './placeholders/productTilePricePlaceholder';
import ProductTileTextSubtitlePlaceholder from './placeholders/productTileTextSubtitlePlaceholder';
import ProductTileFlagStringPlaceholder from './placeholders/productTileFlagString';
import ProductTileStrikethroughPlaceholder from './placeholders/productTileStrikethroughPlaceholder';

import {
  TileWrapperLink,
  TileContent,
  TileTitle,
  TileImageWrapper,
  TilePrice,
  TileSubtitle,
  AdditionalText,
  TileStrikethrough,
  TileTextSubtitle,
  FlagString,
  GiftFlag,
  PriceText,
  LoadingFlagString,
} from './productTile.styles';
import { ImageSrc } from 'src/components/atoms/Image/image.types';

/**
 *
 * @param {() => void} onClick Callback on link click.
 * @param {boolean} [parentIsLoading = false] Indicates if parent is still loading.
 * @param {string} title Provides a product title.
 * @param {string} slug path to link to when clicked.
 * @param {{ type:string, baseUrl:string, path:string }[]} images Provides array of image objects.
 * @param {string} id Product tile id.
 * @param {string} priceText Price text.
 * @param {string} subtitle Subtitle text.
 *  @param {string} priceTextStrikethrough Price text stricken through.
 * @param {string} priceTextSubtitle Price subtitle text.
 * @param {string} flagString Provides flagged text.
 * @param {{ icon: import('../../../icons').IconsType, description: string}[]} giftFlag Provide flag of text and icon.
 *
 */

export const ProductTile = ({ title, slug, images, onClick, testid = 'product-tile', id, priceText, subtitle, priceTextStrikethrough, priceTextSubtitle, flagString, giftFlag, parentIsLoading, className = '' }: ProductTileProps): React.ReactElement => {

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent):void => {
    event.preventDefault();
    onClick();
  };

  if(parentIsLoading) {
    return (
      <TileWrapperLink role="button" data-testid={testid} {...(id ? { id } : {})} className={className}>
        <TileImageWrapper>
          <ProductImage
            image={images ? images[0] as ImageSrc : undefined}
            title={`${title} Cover`}
            imgWidth={160}
            parentIsLoading={parentIsLoading}
          />
        </TileImageWrapper>
        <TileContent>
          <TileTitle>
            <ProductTileTitlePlaceholder />
          </TileTitle>
          <TileSubtitle><ProductTileSubtitlePlaceholder /></TileSubtitle>
          <AdditionalText>
            <PriceText>
              <TilePrice><ProductTilePricePlaceholder /></TilePrice>
              <TileStrikethrough><ProductTileStrikethroughPlaceholder /></TileStrikethrough>
            </PriceText>
            <TileTextSubtitle><ProductTileTextSubtitlePlaceholder /></TileTextSubtitle>
            <LoadingFlagString><ProductTileFlagStringPlaceholder /></LoadingFlagString>
          </AdditionalText>
        </TileContent>
      </TileWrapperLink>
    );
  };

  return (
    <TileWrapperLink href={slug} role="button" onClick={handleClick} data-testid={testid} {...(id ? { id } : {})} className={className}>
      <TileImageWrapper>
        <ProductImage
          image={images ? images[0] as ImageSrc : undefined}
          title={`${title} Cover`}
          imgWidth={160}
          parentIsLoading={parentIsLoading}
          sizes='(min-width: 438px) 185px, 146px'
          srcSetWidths={[100, 146, 185, 200, 292, 370, 400, 500]}
        />
      </TileImageWrapper>
      <TileContent>
        <TileTitle>
          {title}
        </TileTitle>
        {subtitle && <TileSubtitle>{subtitle}</TileSubtitle>}
        <AdditionalText>
          <PriceText>
            {priceText && <TilePrice>{priceText}</TilePrice>}
            {priceTextStrikethrough && <TileStrikethrough>{priceTextStrikethrough}</TileStrikethrough>}
          </PriceText>
          <TileTextSubtitle>{priceTextSubtitle}</TileTextSubtitle>
          {flagString && <FlagString>{flagString}</FlagString>}
          {!flagString && giftFlag &&
          <GiftFlag>
            <FreeGift icon={giftFlag.icon} description={giftFlag.description} />
          </GiftFlag>}
        </AdditionalText>
      </TileContent>
    </TileWrapperLink>
  );
};
