import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { ProductOptionList, ProductOptionGroup, CheckoutActions } from '../../molecules';
import { BenefitCard, ProductImage, Title, RichText, FreeGift } from '../../atoms';

import { executeOnKeyDown } from '../../../utils';

import type { ProductDisplay as ProductDisplayProps } from './productDisplay.types';
import type { ProductOptionListItem as Variant } from '../../molecules/ProductOptionListItem/productOptionListItem.types';
import type { Filter } from '../../molecules/ProductOptionGroup/productOptionGroup.types';
import type { IconsType } from '../../../icons/index';
import { ProductDescriptionPlaceholder, ProductSubtitlePlaceholder, ExtrasPlaceholder, PricingStringPlaceholder, EndPricePlaceholder } from './placeholders/productDisplayPlaceholders';

import {
  ProductWrapper,
  LeftColumn,
  ImageWrapper,
  MainContent,
  ProductHeading,
  MobileImage,
  ProductDesc,
  ProductDescription,
  ReadMoreButton,
  ProductSubscriptions,
  ErrorMessage,
  ActionWrapper,
  ActionContentWrapper,
  ActionLoading,
  MobileBenefitsContainer,
  FeatureSubtitle,
  EndPrice,
  PricingString,
  OptionGroupWrapper
} from './productDisplay.styles';

const benefits = [
  {
    icon: 'arrow_pointer' as IconsType,
    label: 'Cancel any time'
  },
  {
    icon: 'money_pound' as IconsType,
    label: 'Best price guarantee'
  },
  {
    icon: 'truck' as IconsType,
    label: 'No hidden costs'
  },
  {
    icon: 'shield_check' as IconsType,
    label: 'You\'re in control'
  }
];

/**
 *
 * @param {import('./productDisplay.types').Product} product Product to be displayed.
 * @param {string} [title] Title for the variant picker.
 * @param {import('../../molecules/ProductOptionGroup/productOptionGroup.types')[]} [filters] Product variants filters.
 * @param {string} [promotionalCopy] Copy to be added over promoted filter.
 * @param {(id:string, payment:string) => void} onAddToBasket Callback for the add to basket button.
 * @param {boolean} addError If adding item to basket caused an error.
 * @param {boolean} addLoading When adding to basket is processing.
 * @param {string} [notification] Error type.
 * @param {import('../../molecules/CheckoutActions/checkoutActions.types').Option[]} checkoutOptions Available checkout actions.
 * @param {boolean} [showBenefitCard=false] Indicate if to show the BenefitCard.
 * @param {string} [selectionTitle] Title for the ProductVariantDisplay.
 * @param {{freeGiftIcon: string, freeGiftDescription: string}} promotion Any promotion attached to the item.
 * @param {React.ReactNode} [extras] Additional component/functionality.
 * @param {boolean} [parentIsLoading = false] Indicates parent is still loading
 *
 */

export const ProductDisplay = ({ product, title, filters, onAddToBasket, addError, addLoading, notification, onSelect, checkoutOptions, showBenefitCard = false, promotion, testid = 'productdisplay', promotionalCopy, selectionTitle, extras, parentIsLoading = false }:ProductDisplayProps):React.ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const [selectedVariant, setSelectedVariant] = useState<Variant | undefined>(product?.defaultVariant);
  const [variantsToShow, setVariantsToShow] = useState<Variant[] | []>([]);
  const [filterInStock, setFilterInStock] = useState<Filter[]>([]);
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [needReadMoreButton, setNeedReadMoreButton] = useState(false);

  useEffect(() => {
    if(product?.defaultVariant.inStock) {
      setSelectedVariant(product?.defaultVariant);
      if(filters) {
        setFilters(product.defaultVariant, filters);
      }
    } else {
      const selectedFilteredVariant = product?.variants.filter(variant => variant.inStock === true)[0];
      if (selectedFilteredVariant) {
        if (filters) {
          setFilters(selectedFilteredVariant, filters);
        }
        setSelectedVariant(selectedFilteredVariant);
      }
    }
  }, [product]);

  const isButtonDisabled = (): boolean => {
    if (product?.variants && product.variants.length > 0) {
      return product.variants.every(variant => variant.inStock === false);
    }
    return false;
  };

  useEffect(() => {
    if (filters && filters?.length > 0 && product?.variants) {
      const newFilters = filters.map(filter => {
        const filterVariants = product.variants.filter(variant => variant.options?.includes(filter.id));
        const hasStock = filterVariants.some(variant => variant.inStock === true);
        return { ...filter, inStock: hasStock };
      });
      setFilterInStock(newFilters);
    }
  }, [filters, product]);

  const setFilters = (variant: Variant, currentFilters: Filter[]):void => {
    const [selFilter] = currentFilters.filter(flt => variant.options?.includes(flt.id));
    if (selFilter) {
      setVariantsToShow(filterVariants(product?.variants as Variant[], selFilter.id));
      setSelectedFilter(selFilter.id);
    }
  };

  const filterVariants = (allVariants:Variant[], filterId:string):Variant[] => {
    return allVariants.filter(item => item.options?.includes(filterId));
  };

  const onFilter = (filterId:string):void => {
    setSelectedFilter(filterId);
    const filteredVariants = filterVariants(product?.variants as Variant[], filterId);
    if(filteredVariants) {
      setVariantsToShow(filteredVariants);
      setSelectedVariant(filteredVariants[0]);
    }
  };

  const handleSelectedVariant = (variantCode:string): void => {
    const selected = product?.variants.filter(variant => variant.sku === variantCode && variant.inStock === true)[0];
    setSelectedVariant(selected);
    if(onSelect) onSelect();
  };

  const hasSelection = ():boolean => (!!filters && filters.length > 0) || (!!product?.variants && product.variants.length > 1);

  useLayoutEffect(() => {
    const descriptionEl = descriptionRef.current;
    const scrollHeight = descriptionEl?.scrollHeight;
    const offsetHeight = descriptionEl?.offsetHeight;
    if (scrollHeight && offsetHeight && scrollHeight > offsetHeight) {
      setNeedReadMoreButton(true);
    }
  }, [product?.description]);

  const productDescriptionID = `product-description${product?.id ? '-' + product.id : '' }`;

  return (
    <ProductWrapper data-testid={`${testid}-wrapper`} >
      <LeftColumn>
        <ImageWrapper>
          <ProductImage
            title={product?.title || ''}
            imgWidth={240}
            orientation='portrait'
            image={product?.images ? product.images[0] : undefined}
            parentIsLoading={parentIsLoading}
            sizes='(min-width: 768px) 240px, 220px'
            srcSetWidths={[220, 240, 440, 480]}
            loading='eager'
            testid={product?.title.replaceAll(' ', '')}
          />
        </ImageWrapper>
        {parentIsLoading ? <BenefitCard benefits={benefits} parentIsLoading={parentIsLoading} /> : showBenefitCard && <BenefitCard benefits={benefits} />}
      </LeftColumn>
      <MainContent>
        <ProductHeading>
          <Title tag='h1' text={product?.title || ''} parentIsLoading={parentIsLoading} />
        </ProductHeading>
        {parentIsLoading ? (
          <ProductSubtitlePlaceholder />
        ) : (
          <>
            {product?.subtitle &&
              <FeatureSubtitle>
                <div data-testid={`${testid}-subtitle`} dangerouslySetInnerHTML={{ __html: product.subtitle }}/>
              </FeatureSubtitle>}
          </>
        )}
        <MobileImage>
          <ProductImage
            title={product?.title || ''}
            imgWidth={220}
            orientation='portrait'
            image={product?.images ? product.images[0] : undefined}
            parentIsLoading={parentIsLoading}
            sizes='(min-width: 768px) 240px, 220px'
            srcSetWidths={[220, 240, 440, 480]}
            loading='eager'
          />
        </MobileImage>
        <ProductDesc>
          {parentIsLoading ? (
            <ProductDescriptionPlaceholder />
          ) : (
            <>
              <RichText><ProductDescription id={productDescriptionID} ref={descriptionRef} expanded={expanded} needReadMoreButton={needReadMoreButton} dangerouslySetInnerHTML={{ __html: product?.description || '' }}/></RichText>
              {needReadMoreButton && (
                expanded ? (
                  <ReadMoreButton role="button" data-testid="show-less" tabIndex={0} onClick={() => setExpanded(false)} onKeyDown={(e: React.KeyboardEvent) => executeOnKeyDown(e) && setExpanded(false)} aria-label='Read fewer details' aria-expanded={expanded} aria-controls={productDescriptionID}>Read fewer details</ReadMoreButton>
                ) : (
                  <ReadMoreButton role="button" data-testid="show-more" tabIndex={0} onClick={() => setExpanded(true)} onKeyDown={(e: React.KeyboardEvent) => executeOnKeyDown(e) && setExpanded(true)} aria-label='Read more details' aria-expanded={expanded} aria-controls={productDescriptionID}>Read more details</ReadMoreButton>
                )
              )}
            </>
          )}

        </ProductDesc>
        <ProductSubscriptions>
          {hasSelection() && <Title text={title || ''} tag='h2' parentIsLoading={parentIsLoading} />}
          <OptionGroupWrapper hasOptions={filterInStock.length > 1}>
            <ProductOptionGroup
              variants={filterInStock && filterInStock.length > 1 ? filterInStock : undefined}
              optionSelected={selectedFilter}
              onSelect={onFilter}
              promotionCopy={promotionalCopy || ''}
              parentIsLoading={parentIsLoading}
            />
          </OptionGroupWrapper>
          {parentIsLoading ? <ExtrasPlaceholder /> : extras && <div>{extras}</div>}
          {parentIsLoading ? (
            <ProductOptionList parentIsLoading={parentIsLoading} />
          ) : (
            <>
              {product?.variants && product?.variants.length > 1
                ? variantsToShow.length > 1 && <ProductOptionList
                  variants={variantsToShow}
                  onSelect={handleSelectedVariant}
                  selected={selectedVariant?.sku}
                  title={selectionTitle}
                />
                : <Title text={`${product?.defaultVariant?.title} for ${product?.defaultVariant?.price}`} tag='h2' parentIsLoading={parentIsLoading} />
              }
            </>
          )}
          {addError && notification === 'add' && <ErrorMessage>Sorry, there was an error adding the item please try again later.</ErrorMessage>}
          {parentIsLoading ? (
            <FreeGift parentIsLoading={parentIsLoading} />
          ) : (
            <>
              {promotion && <FreeGift testid={`${testid}-freegift`} icon={promotion.freeGiftIcon} description={promotion.freeGiftDescription} />}
            </>
          )}
          {!isButtonDisabled() && (
            <>
              {parentIsLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <EndPricePlaceholder />
                  <PricingStringPlaceholder />
                </div>
              ) : product?.variants && product.variants.length > 1 && selectedVariant?.inStock &&
            <>
              <EndPrice>Total price today: <span>{selectedVariant?.price}</span></EndPrice>
              <PricingString>{selectedVariant?.humanReadablePricingString}</PricingString>
            </>
              }
            </>
          )}
          <ActionWrapper>
            <ActionContentWrapper>
              <ActionLoading addLoading={addLoading} />
              <CheckoutActions testid='pdp-action' options={checkoutOptions} onOptionSelect={selected => onAddToBasket(selectedVariant?.sku as string, selected)} disabled={isButtonDisabled()}/>
            </ActionContentWrapper>
          </ActionWrapper>
        </ProductSubscriptions>
        {parentIsLoading ? <MobileBenefitsContainer><BenefitCard benefits={benefits} parentIsLoading={parentIsLoading} /></MobileBenefitsContainer> : showBenefitCard && <MobileBenefitsContainer><BenefitCard benefits={benefits} /></MobileBenefitsContainer>}
      </MainContent>
    </ProductWrapper>
  );
};
