import React from 'react';
import type { SingleIssue as SingleIssueProps } from './singleIssue.types';
import { ProductImage, Button } from '../../atoms';
import { SingleIssueTitlePlaceholder } from './placeholders/singleIssuePlaceholders';

import {
  SingleIssueWrapperLink,
  SingleIssueContent,
  SingleIssueTitle
} from './singleIssue.styles';

/**
 *
 * @param {string} title Provides a title for the item.
 * @param {{ type:string, baseUrl:string, path:string }} images Provides an image object.
 * @param {string} imageSizes Applies sizes value to image
 * @param {string} buttonText Button text.
 * @param {boolean} disabled  Sets the button state.
 * @param {string} [parentIsLoading = false] Indicates if parent is still loading.
 * @param {() => void} onClick Single issue click callback.
 *
 **/

export const SingleIssue = ({ title, images, imageSizes, disabled, buttonText, parentIsLoading, testid = 'single-issue', onClick, slug }:SingleIssueProps ): React.ReactElement => {

  const handleClick = (event: React.MouseEvent | React.KeyboardEvent): void => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  if (parentIsLoading) {
    return (
      <SingleIssueWrapperLink data-testid={testid}>
        <ProductImage
          image={undefined}
          title=''
          imgWidth={285}
          parentIsLoading
        />
        <SingleIssueContent>
          <SingleIssueTitle><SingleIssueTitlePlaceholder /></SingleIssueTitle>
          <Button testid={testid} btnType='filled' size='large' disabled responsive>{buttonText}</Button>
        </SingleIssueContent>
      </SingleIssueWrapperLink>
    );
  }

  return (
    <SingleIssueWrapperLink href={slug} onClick={handleClick} data-testid={testid}>
      <ProductImage
        image={images ? images[0] : undefined}
        title={`${title} Cover`}
        imgWidth={285}
        parentIsLoading={parentIsLoading}
        srcSetWidths={[250, 300, 338, 400, 450, 500, 550, 600, 650, 676]}
        sizes={imageSizes ?? '(min-width: 400px) 400px, 100%'}
      />
      <SingleIssueContent>
        <SingleIssueTitle><h4>{title}</h4></SingleIssueTitle>
        <Button testid={testid} btnType='filled' size='large' disabled={parentIsLoading || disabled} responsive onClick={handleClick}>{buttonText}</Button>
      </SingleIssueContent>
    </SingleIssueWrapperLink>
  );
};
