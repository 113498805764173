import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 40px 0;
`;

export const TopBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  margin-bottom: 30px;
`;

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.fs6};
  line-height: ${({ theme }) => theme.lineHeights.lh5};
  font-weight: ${({ theme }) => theme.fontWeights.fw2};
  text-align: center;
`;

export const Arrows = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  svg {
    height: 23px;
    width: auto;
  }

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const ArrowCircle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.red};
  cursor: pointer;
  background: none;

  &.disabled {
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};

    svg path {
      fill: ${({ theme }) => theme.colors.grey};
    }
  }

`;

export const ScrollContainer = styled('div').withConfig({
  shouldForwardProp: props => !['flexGap'].includes(props)
})<{ flexGap: number }>`
  display: flex;
  flex-flow: row nowrap;
  gap: ${({ flexGap }) => `${flexGap}px`};
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
  padding-bottom: 10px;

  > * {
    flex-shrink: 0;
  }
`;

const dotHeight = '15px';

export const PageDots = styled('div').withConfig({
  shouldForwardProp: props => !['activePage'].includes(props)
})<{ activePage: number }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 15px;
  min-height: ${dotHeight};

  button:nth-of-type(${({ activePage }) => activePage + 1}) {
    width: 25px;
    background: ${({ theme }) => theme.colors.black};
  }
 `;

export const Dot = styled.button`
  width: 15px;
  height: ${dotHeight};
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.grey};
  border: 0;
  transition: 0.15s ease;
  cursor: pointer;
`;
