import { styled, css } from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: block;
`;

export const Select = styled.select`
  display: none;
`;

export const SelectHeader = styled('div').withConfig({
  shouldForwardProp: props =>
    !['error', 'isOpen', 'parentIsLoading'].includes(props)
})<{ disabled?: boolean, error?: boolean, isOpen?: boolean, parentIsLoading?: boolean }>`
  min-height: 44px;
  border: 1px solid;
  cursor: pointer;
  width: 100%;
  border-color: ${props => props.theme.colors.grey};
  border-radius: ${props => props.theme.borderRadiuses.br0};
  padding: ${props => props.theme.spacerSizes.ss2} ${props => props.theme.spacerSizes.ss3};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};

  label {
    color: ${props => props.theme.colors.darkGrey};
  }

  span {
    color: ${props => props.theme.layout.primary};
  }
  ${props => props.disabled && css`
    color: ${props.theme.colors.grey};
    border-color: ${props.theme.colors.grey};
    background-color: ${props.theme.colors.lightestGrey};
  `}
  ${props => props.isOpen && css`
    border-bottom: 0 none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: ${props.theme.colors.darkGrey};
  `}
  ${props => props.error && css`
    border-color:${props.theme.colors.error};
    border: 2px solid;
  `}

  ${props => props.parentIsLoading && css`
    cursor: default;

    .fa-angle-down {
      color: ${props.theme.colors.darkGrey};
    }
  `}
`;

export const HeaderText = styled.div`
  margin-right: 10px;
`;

export const SelectUL = styled('ul').withConfig({
  shouldForwardProp: props =>
    !['isOpen', 'error', 'hHeight'].includes(props)
})<{ isOpen?: boolean, error?: boolean, hHeight?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid;
  margin-top: ${props => props.hHeight}px;
  max-height: 220px;
  overflow: auto;
  z-index: ${props => props.theme.zIndexes.zi4};
  border-color: ${props => props.theme.colors.grey};
  border-radius: ${props => props.theme.borderRadiuses.br0};
  ${props => props.isOpen && css`
    border-color: ${props => props.theme.colors.darkGrey};
    background-color: ${props => props.theme.colors.white};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 15px 20px ${props => props.theme.colors.grey};
  `}
  ${props => props.error && css`
    border-color: ${props.theme.colors.error};
  `}
`;

export const SelectLi = styled.li`
  height: inherit;
  padding: 10px 15px;
  margin-top: 1px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.lightGrey};
  }
`;

export const InputCheck = styled.div`
  svg {
    position: absolute;
    top: 14px;
    right: 10px;
    display: block;
    padding-left: 9px;
  }
`;

export const Options = styled.div`
  label {
    color: ${props => props.theme.colors.darkGrey};
  }

  span {
    color: ${props => props.theme.layout.primary};
  }
`;

export const FilterInput = styled('input').withConfig({
  shouldForwardProp: props => !['error', 'isOpen', 'searchable'].includes(props)
})<{ disabled?: boolean; error?: boolean; isOpen?: boolean; searchable?: boolean }>`
  min-height: 46px;
  border: 1px solid;
  cursor: pointer;
  width: 100%;
  border-color: ${props => props.theme.colors.grey};
  border-radius: ${props => props.theme.borderRadiuses.br0};
  padding: ${props => props.theme.spacerSizes.ss2} ${props => props.theme.spacerSizes.ss3};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grey};
      border-color: ${props.theme.colors.grey};
      background-color: ${props.theme.colors.lightestGrey};
    `}
  ${props =>
    props.isOpen &&
    css`
      border-bottom: 0 none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: ${props.theme.colors.darkGrey};
    `}
  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.error};
      border: 2px solid;
    `}

  ${props =>
    props.searchable &&
    css`
      font-size: ${props => props.theme.fontSizes.fs2};

    &::placeholder {
        color: ${props => props.theme.colors.black};
      }
    `}
`;

export const NoMatchingOptions = styled.li`
  padding: 10px 20px;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    margin-right: 20px;
    padding-right: 9px;
    padding-top: 1px;
    border-right: 1px solid ${props => props.theme.colors.grey};
  }
`;
