const ENV = process.env.NODE_ENV || 'development';
const LOCAL_ENV = 'http://localhost:3000';

export const ARCADE_API_STAGING = 'https://hf-placements.arcade-api.magsdirect-staging-eks-euw1.futureplc.engineering';
export const ARCADE_API_PRODUCTION = 'https://api.arcade.futurecdn.net';

export const apiUrlByEnv = {
  development: ARCADE_API_STAGING,
  production: ARCADE_API_PRODUCTION,
  staging: ARCADE_API_STAGING,
  test: LOCAL_ENV
}[ENV];
