import { styled, css } from 'styled-components';

export const SelectorWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 65px;
`;

export const SelectorLink = styled.a`
  padding: 4px 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  max-width: 45px;
  text-align: center;
  color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.layout.primary : theme.colors.white};
    text-decoration: underline;
  }

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    flex-flow: row nowrap;
    padding: ${props => props.theme.spacerSizes.ss2};
    max-width: none;
  }
`;

export const SelectorFlag = styled.div`
  order: 1;
`;

export const SelectorLabel = styled.span`
  font-size: ${props => props.theme.fontSizes.fs0};
  line-height: ${props => props.theme.lineHeights.lh0};
  padding: 4px 0 0;
  order: 3;

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    order: 2;
    font-size: ${props => props.theme.fontSizes.fs1};
    line-height: ${props => props.theme.lineHeights.lh1};
    padding: 0 5px 0 0;
  }
`;

export const SelectorIcon = styled.div<{ open: boolean}>`
  line-height: 14px;
  order: 2;

  svg {
    color: ${({ theme }) => theme.layout.header.mode === 'light' ? theme.colors.black : theme.colors.white};
    line-height: 14px;
    font-size: ${props => props.theme.fontSizes.fs1};
    ${props => props.open && css`
      transform: rotate(180deg);
    `}
  }

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    order: 3;

    svg {
      line-height: ${props => props.theme.lineHeights.lh2};
      font-size: ${props => props.theme.fontSizes.fs2};
    }
  }
`;

export const SelectorDrop = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  display: ${props => props.open ? 'block' : 'none'};
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  z-index: ${props => props.theme.zIndexes.zi3};
  transform: translateX(calc(-50% + 32px));
  width: max-content;
  filter:
    drop-shadow(0 32px 64px rgba(1, 1, 1, 0.07))
    drop-shadow(0 16px 32px rgba(1, 1, 1, 0.07))
    drop-shadow(0 8px 16px rgba(1, 1, 1, 0.07))
    drop-shadow(0 4px 8px rgba(1, 1, 1, 0.07))
    drop-shadow(0 2px 4px rgba(1, 1, 1, 0.07))
    drop-shadow(0 1px 2px rgba(1, 1, 1, 0.07));

  @media (min-width: ${props => props.theme.breakpoints.laptop}) {
    transform: translateX(calc(-50% + 53px));
  }
`;

export const DropItem = styled.li`
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  width: 100%;
  font-size: ${props => props.theme.fontSizes.fs1};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fontWeights.fw1};
  line-height: ${props => props.theme.fontSizes.fs1};
  display: block;

  &:last-child {
    border-bottom: 0;
  }

  a {
    padding: 15px 15px 15px 40px;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;

    span {
      margin-left: 5px;
      vertical-align: baseline;
    }

    &.selected {
      pointer-events: none;
    }
  }
`;

export const SelectedIcon = styled.div`
  position: absolute;
  left: 15px;
  top: 14px;

  svg {
    font-size: ${props => props.theme.fontSizes.fs2};
  }
`;
