import { styled } from 'styled-components';

export const GroupWrapper = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: -105%;
  transition: all 0.5s ease-in-out;
  ${props => props.open && 'left: 0;'}
`;

export const GroupHeader = styled.div`
  padding: 20px 15px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.beige};
  position: relative;
`;

export const CloseButton = styled.button`
  width: 34px;
  height: 34px;
  position: absolute;
  right: 15px;
  top: 16px;
  padding: 0;
  margin: 0;
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  filter: drop-shadow(0 0 2px ${props => props.theme.colors.lightGrey});

  svg {
    font-size: 24px;
  }
`;

export const GroupContent = styled.div`
  overflow: auto;
  display: block;
  height: 100%;
  border-top: 1px solid ${props => props.theme.colors.beige};
`;

export const GroupContentInner = styled.div`
  padding: 0 15px;
`;

export const FilterWrapper = styled.div`
  padding-bottom: 20px;

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const GroupActions = styled.div`
  padding: 20px 15px;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.colors.beige};
  align-self: flex-end;
  width: 100%;

  button:first-child {
    margin-bottom: 10px;
  }
`;
